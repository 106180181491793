@font-face {
  font-family: "ModellicaMedium";
  src: local("ModellicaMedium"), local("Modellica-Medium"),
    url("./assets/fonts/BWModelica/BwModelica-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModellicaMedium2";
  src: local("ModellicaMedium2"), local("Modellica-Medium2"),
    url("./assets/fonts/BWModelica/BwModelicaSS02-Medium.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ModellicaBold";
  src: local("ModellicaBold"), local("Modellica-Bold"),
    url("./assets/fonts/BWModelica/BwModelica-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ModellicaBold2";
  src: local("ModellicaBold2"), local("Modellica-Bold"),
    url("./assets/fonts/BWModelica/BwModelicaSS02-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RaitorRegular";
  src: local("RaitorRegular"), local("RaitorRegular"),
    url("./assets/fonts/raitor-futuristic-display-fonts/Raitor-Regular.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MadeOuterRegular";
  src: local("MadeOuterRegular"), local("MadeOuterRegular"),
    url("./assets/fonts/made_outer_sans/MADE\ Outer\ Sans\ Regular\ PERSONAL\ USE.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MadeOuterBold";
  src: local("MadeOuterBold"), local("MadeOuterBold"),
    url("./assets/fonts/made_outer_sans/MADE\ Outer\ Sans\ Bold\ PERSONAL\ USE.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MadeOuterMedium";
  src: local("MadeOuterMedium"), local("MadeOuterMedium"),
    url("./assets/fonts/made_outer_sans/MADE\ Outer\ Sans\ Medium\ PERSONAL\ USE.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MadeOuterLight";
  src: local("MadeOuterLight"), local("MadeOuterLight"),
    url("./assets/fonts/made_outer_sans/MADE\ Outer\ Sans\ Light\ PERSONAL\ USE.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
  position: relative;
  background-color: #000;
  overflow: hidden;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: #e3e3e3 transparent;
  scrollbar-width: thin;
  -webkit-text-size-adjust: 100%;
  background: #000000;
}
* {
  font-variant-numeric: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-color: #e3e3e3 transparent;
  scrollbar-width: thin;
  font-family: "ModellicaMedium", sans-serif;
}
h1,
h2,
h4,
h5 {
  font-family: "ModellicaBold", sans-serif;
}
h3 {
  font-family: "MadeOuterBold", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #2f2f2f;
}
::-webkit-scrollbar-thumb:active {
  background: #2f2f2f;
}
::-webkit-scrollbar-track {
  background: #100f0f;
}
::-webkit-scrollbar-track:hover {
  background: #100f0f;
}
::-webkit-scrollbar-track:active {
  background: #100f0f;
}
::-webkit-scrollbar-corner {
  background: #100f0f;
}
.ant-select-dropdown-hidden:first-child,
.ant-select-dropdown:first-child {
  max-height: 300px !important;
  overflow: auto !important;
}
.ant-input {
  background-color: transparent;
}
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(
    .ant-input-number-affix-wrapper-borderless
  ).ant-input-number-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(
    .ant-input-number-affix-wrapper-borderless
  ).ant-input-number-affix-wrapper:hover {
  background: transparent !important;
  margin: 0;
}

.ant-form-item-explain-error,
.ant-form-item-with-help .ant-form-item-explain {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ant-popover-inner {
  background: #141414e0 !important;
  max-width: 300px !important;
  min-height: 350px;
  border: 1px solid #fdb91399;
  color: #fff !important;
}

.ant-popover-arrow {
  display: none !important;
}

.ant-spin-dot-item {
  background: #fdb913 !important;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999 !important;
  background-color: #999999 !important;
  color: #fff;
}

input:disabled,
input[disabled] {
  border: 1px solid #99999969 !important;
  background-color: #99999969 !important;
  color: transparent !important;
}
input:disabled::placeholder,
input[disabled]::placeholder {
  color: transparent !important;
}
.ant-picker-header {
  display: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #000000 inset !important;
  -webkit-text-fill-color: #fff !important;
}

.ant-input-affix-wrapper:hover {
  border-color: #fcb31699 !important;
}

.confirmStatus {
  color: #ee3a3a;
  font-size: 12px;
}

[ant-click-animating-without-extra-node="true"]::after,
.ant-click-animating-node {
  display: none !important;
}

.ant-select-dropdown {
  background-color: #000000 !important;
  border: 1px solid #ffffff99 !important;
}
.ant-select-item {
  color: #fff !important;
}

.ant-select-item:hover,
.ant-select-item-option-active {
  background-color: #000 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled):hover {
  background-color: #000 !important;
  color: #fff !important;
}
.ant-picker-panel {
  background-color: #000000 !important;
  border: 1px solid #ffffff99 !important;
}
.ant-picker-cell-disabled {
  display: none;
}
.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #fcb316 !important;
  color: #fff !important;
}
.ant-picker-cell .ant-picker-cell-inner {
  color: #fff !important;
}
.ant-picker-cell:hover .ant-picker-cell-inner {
  background-color: #fcb31699 !important;
}
.ant-picker-content thead tr th {
  color: #fff;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #fcb316 !important;
}
.ant-select-dropdown {
  width: max-content !important;
}
.dial_code {
  width: 40px;
  text-align: start;
  font-size: 12px;
}
.country_name {
  width: 150px;
  text-align: start;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.country_name .country_flag {
  margin-right: 10px;
  overflow: hidden;
  border-radius: 100%;
}
.country_flag img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.ant-empty-description {
  color: #ffffff;
  font-size: 12px;
}

#active_country {
  background: #ffae00 !important;
}

.country_item:hover {
  background-color: #ffffff44 !important;
}
